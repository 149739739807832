import React from 'react'
import { Link, navigate, withPrefix } from 'gatsby'
import { FaArrowLeft } from 'react-icons/fa'

import { Container, Row, Col, Button } from 'reactstrap'

import { isLoggedIn } from '../utils/auth';

import { logout } from '../utils/auth';

class StickyHeader extends React.Component {

  constructor(props){
    super(props)
  }

  handleLogout(){
    logout();
    navigate('/login');
  }

  render(){

    const { title, back, complete } = this.props;

    return (
        <div style={{
          position: `fixed`,
          minHeight: `56px`,
          zIndex: `100`,
          width: `100%`,
          top: `0`,
          backgroundColor: complete ? `#2ecc71` : `#333`,
          transition: `.5s linear`,
          borderColor: `#b2b2b2`,
          textTransform: `uppercase`,
          display: `flex`,
          alignItems: `center`,
          boxShadow: `0 0 7px 2px rgba(0,0,0,0.5)`
        }}>

          <span style={{
              color: `#fff`,
              padding: `15px`,
              marginLeft: `0px`,
              fontWeight: `bold`
          }}>

            {
              back ? (
                <a href={withPrefix(back)} style={{
                  boxShadow: `none`,
                  color: `#fff`,
                  fontSize: `1.2em`,
                  marginRight: `15px`
                }}><FaArrowLeft /></a>
              ) : (<span></span>)
            }

            {title}

          </span>

            {
              isLoggedIn() && 
              <Button className="d-none d-md-block" style={{position: `absolute`, right: `20px`, color: `#fff`}} onClick={this.handleLogout}>
                Log Out
              </Button>
            }

        </div>
    )
  }

}

export default StickyHeader