import React from 'react'
import { Link } from 'gatsby'
import StickyHeader from './sticky-header';

import Helmet from 'react-helmet'

import { css, injectGlobal } from 'react-emotion';

injectGlobal`

  body {
    background-color: #5b5b5b;
    background-image: linear-gradient(180deg,#2f2f2f 0,#5b5b5b 100%);
    background-repeat: repeat-x;
    height: 100%;
  }

`

const globalStyles = css`

  font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
  h1, h2, h3, h4, h5, h6 {
    
    
  }

  p, ul, li {
    font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    line-height: 1.8em;
  }

  ul, li {

  }

`;

class Layout extends React.Component {
  render() {
    const { children, title, back, complete } = this.props

    return (
      <div className={globalStyles} style={{paddingTop: `56px`}}>
        <StickyHeader title={title} back={back} complete={complete} />
        {children}
      </div>
    )
  }
}

export default Layout
