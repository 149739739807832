export const handleLogin = ({license, order}) => {

    return new Promise((resolve, reject) => {

        //postData(`https://elite-ionic-server.herokuapp.com/auth`, {order: order, license: license})
        postData(`/.netlify/functions/auth`, {profile: { order: order.trim(), license: license.trim()}})
        .then((data) => {

            console.log(data);

            if(typeof(data.token) != "undefined"){
                let expires = new Date();
                expires.setMonth(expires.getMonth() + 2);
                let cookie = "eijwt=" + data.token + ";expires=" + expires.toGMTString() + ";domain=eliteionic.com;path=/"
                document.cookie = cookie;
                resolve(true);
            } else {
                reject(false);
            }

        })
        .catch((error) => {
            console.error(error)
            reject(false);
        });

    })

}

export const isLoggedIn = () => {

    if(typeof document !== 'undefined'){

        if(document.cookie.indexOf('eijwt') == -1){
            return false
        } else {
            return true
        }

    }
    
    return false

}

export const logout = () => {

    document.cookie = "eijwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=eliteionic.com;path=/"

}

const postData = (url = ``, data = {}) => {

    // Default options are marked with *
      return fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(response => response.json()); // parses response to JSON

  }