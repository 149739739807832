export const addCompletedLesson = (id) => {

    let completedLessons = getCompletedLessons();

    completedLessons.push(id);
    
    try {
        window.localStorage.setItem('elite-complete-lessons', JSON.stringify(completedLessons));
    } catch(e){

    }   

}

export const removeCompletedLesson = (id) => {


    let completedLessons = getCompletedLessons();

    let index = completedLessons.indexOf(id);

    if(index > -1){
        completedLessons.splice(index, 1);

        try {
            window.localStorage.setItem('elite-complete-lessons', JSON.stringify(completedLessons));
        } catch(e){

        }   
   
    }

}

export const isCompleted = (id) => {

    let completedLessons = getCompletedLessons();

    let index = completedLessons.indexOf(id);

    if(index > -1){
        return true;
    } else {
        return false;
    }

}

const getCompletedLessons = () => {

    let completedLessons = [];
    let lessons = false;

    try {
        lessons = window.localStorage.getItem('elite-complete-lessons');
    } catch(e){

    }   

    if(lessons && lessons !== null){
        completedLessons = JSON.parse(lessons);
    }

    return completedLessons;

}