import React from 'react'
import * as _ from 'lodash'
import { withPrefix } from 'gatsby'

import { Container, Row, Col, Card, CardImg, CardTitle, CardText, CardBody } from 'reactstrap'
import { FaCheckCircle } from 'react-icons/fa';

class LessonItem extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      completed: this.props.completed
    }
  }

  render() {

    const details = this.props.details;
    const headerImg = details.frontmatter.video === null ? 'article.png' : 'video.png';


    return (
      
      <Col key={details.frontmatter.module + details.frontmatter.lesson} lg={{ size: 4}}> 

        <Card style={{margin: `20px`, backgroundColor: `#e7edf1`, boxShadow: `0px 2px 10px 2px black`}}>
          <a style={{margin: `auto`}} href={withPrefix('/angular' + details.frontmatter.module + details.frontmatter.lesson)}>
            <CardImg style={{marginBottom: `0px`, maxWidth: `200px`}} top width="100%" src={withPrefix('/media/angular/' + headerImg)} />
          </a>

          {
              this.state.completed && 
              <p style={{backgroundColor: `#2ecc71`, margin: `0`, height: `30px`, display: `flex`, alignItems: `center`, justifyContent: `center`, padding: `5px`}}>
                <FaCheckCircle style={{color: `#fff`}} />
              </p>
          }

          <CardBody style={{backgroundColor: `#fff`}}>
            <CardTitle style={{marginTop: `0`}}>              
              <a style={{ boxShadow: 'none', color: `#000` }} href={withPrefix('/angular' + details.frontmatter.module + details.frontmatter.lesson)}>
                Lesson {details.frontmatter.lesson}: {details.frontmatter.title}
              </a>    
            </CardTitle>
            <CardText>{details.frontmatter.summary}</CardText>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default LessonItem
