import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import LessonItem from '../components/lesson-item';
import { isCompleted } from '../utils/storage';

import { Row } from 'reactstrap'

class LessonsIndex extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const title = 'Select a Lesson'

    return (
      <Layout location={this.props.location} title={this.props.pageContext.title} back={'/angular/'}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />

        <div>
          <Row>
            {posts.map(({ node }) => {

                let complete = false;

                if(isCompleted(node.frontmatter.module + node.frontmatter.lesson.toString())){
                  complete = true;
                }

                return (
                    <LessonItem key={node.frontmatter.module + node.frontmatter.lesson} details={node} completed={complete} />
                )
            })}
          </Row>
        </div>

      </Layout>
    )
  }
}

export default LessonsIndex

export const pageQuery = graphql`
  query lessonsByUrl($url: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
        filter: { frontmatter: {module: { eq: $url }}}, 
        sort: { fields: [frontmatter___lesson], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            url
            module
            lesson
            summary
            video
          }
        }
      }
    }
  }
`